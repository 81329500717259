.btn-menu {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
}

.btn-menu svg path {
  fill: #000;
}
