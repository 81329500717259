/* Estlos Login */

.app_form{
  max-width: 600px;
  width: 100%;
  background-color: #fff;
  border-radius: 0 5px 5px 0;
}

.form {
  width:100%;
  padding: 60px;
}

.form h2 {
  color: var(--color-secundario);
  font-weight: 600;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 30px;
}

.tt-login .form form {
  max-width: 250px;
  width: 250px;
}

.form form input, .form form select {
  width: 100%;
  height: auto;
  border: none;
  border-bottom: 0.5px solid #9da6b8;
  margin-bottom: 30px;
  outline: 0;
  line-height: 11.72px;
  border-radius: 0;
  padding: 3px 0;
  letter-spacing: 0.85px;
  font-size: 14px;
  margin-top: 15px;
}

.form form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../../../src/assets/icons/arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  color: #9da6b8;
}

.form form select {
  box-shadow: none !important;
}

.form-control:focus, .btn-sl:focus {
  outline: 0;
  border-color: none;
  box-shadow: none;
}

.form-control::placeholder {
  color: #9da6b8;
  font-weight: 400;
  font-size: 14px;
}

.btn-sl {
  padding: 10px 30px;
  background-color: #000000;
  color: #fff;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  border: none;
  max-width: 160px;
}

.form-check-label {
  color: #686868;
  font-weight: 400;
  font-size: 12px;
}

.form-check-input {
  position: absolute;
  margin-top: 8px;
  margin-left: -17px;
}

.form .recover p {
  color: var(--color-secundario);
  font-weight: 400;
  font-size: 12px;
  font-style: italic;
  padding-left: 60px;
  margin: 0;
  cursor: pointer;
}

.form .help a {
  color: #686868;
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: underline;
}

.form .help a:hover {
  color: var(--color-secundario);
}

/* Estilos error input */

.error {
  border: 1px solid #eb6464 !important;
  color: #eb6464 !important;
  position: relative;
}

.error-label {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 12px;
  display: block;
  text-align: center;
  letter-spacing: 0px;
  color: #eb6464;
  opacity: 1;
}

/* Estilos modal */

.modal-body {
  padding: 0 30px;
  text-align: center;
}

.modal-body p {
  color: #686868;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0px;
  font-style: normal;
  line-height: 24px;
}

.modal-header {
  padding: 0;
  border-bottom: none;
  padding-right: 7px;
}

.modal-footer {
  border-top: none;
}

.modal a {
  color: #045f92 !important;
  cursor: pointer;
}

h1.brand {
  margin: 0 15px;
  padding: 0;
  color: #FF8B03;
  font-weight: 700;
  letter-spacing: 5px;
  font-size: 60px;
}

.brand span {
  color: #333;
}


.tt-login svg {
  margin: 0 4px;
}

.tt-login h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.85px;
  color: #333;
  margin-bottom: 8px;
  margin-top: 20px;
}

.recoverPassModalText {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.85px;
  color: #333;
  text-decoration: underline;
  margin-bottom: 0;
}

.login_app{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}