.buttonsTable {
  margin: 0 2px;
  padding: 7px 15px!important;
  position: relative!important;

}
/* position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, margin: 'auto' */
.buttonsTable svg path {
  fill: #fff;
}

.btn {
  outline: 0 !important;
  box-shadow: 0 0 0 0 transparent !important;
  border-radius: 4px;
  border: solid 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 2px;
  padding: 7px 25px;
}

.btn-icon{
  padding: 5px 8px!important;
}

/* .btn.btn-rounded-border {
  border-radius: 50px;
} */

.btn-primary {
  background-color: var(--secondary-color);
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.btn-primary:hover, .btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--primary-color-hover);
  border-color: var(--primary-color-hover);
  color: var(--white-color);
}

.btn-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--white-color);
}

.btn-secondary:hover {
  background-color: var(--secondary-color-hover);
  border-color: var(--secondary-color-hover);
  color: var(--white-color);
}

.btn-danger {
  background-color: #c0392b !important;
  border-color: #c0392b !important;
  color: #fafafa !important;
}

.btn-danger:hover {
  background-color: #e74c3c !important;
  border-color: #e74c3c !important;
  color: #fafafa !important;
}

.btn-white {
  background-color: var(--white-color);
  border-color: var(--black-color);
  color: var(--black-color);
}

.btn-white:hover, .btn-white:active, .btn-white:not(:disabled):not(.disabled):active {
  background-color: var(--black-color);
  border-color: var(--black-color);
  color: var(--white-color);
}

.buttonsFloatCircle {
  border-radius: 100%;
  height: 50px;
  width: 50px;
  position: absolute;
  right: 15px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #000;
}

.buttonsFloatCircle svg path{
  fill:#fff
}



.btn-see {
  background-color: #2980b9;
  border-color: #2980b9;
  color: var(--white-color);
}

.btn-see:hover {
  background-color: #3498db;
  border-color: #3498db;
  color: var(--white-color);
}

.btn-delete {
  background-color: #c0392b !important;
  border-color: #c0392b !important;
  color: #fafafa !important;
}

.btn-delete:hover {
  background-color: #e74c3c !important;
  border-color: #e74c3c !important;
  color: #fafafa !important;
}

.btn-edit {
  background-color: #16a085;
  border-color: #16a085;
  color: var(--white-color);
}

.btn-edit:hover {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: var(--white-color);
}




.btn-download {
  background-color: #2178FC !important;
  border-color: #2178FC !important;
  color: #fafafa !important;
}

.btn-download:hover {
  background-color: #9CCFFF !important;
  border-color: #9CCFFF !important;
  color: #fafafa !important;
}