/* .border-main {
	border-right: 1px solid #dee2e626!important;
} */


.border-bottom {
  border-bottom: 1px solid #dee2e626 !important;
}

.bg-main {
  background-color: var(--bg-sidebar);
  color: #fafafa;
}

.sidebar-heading {
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  padding: 15px !important;
}

.sidebar-heading h5 {
  margin: 0;
  padding: 0;
}

.sidebar-heading .mleft {
  position: absolute;
  right: 15px;
  border-radius: 100%;
}

.list-group-item {
  padding: 13px 15px;
  display: flex;
  align-items: center;
  border: none;
  font-weight: 400;
  /* height: 60px; */
  font-size: 13px;
  line-height: 1.2;
}

.list-group-flush .list-group-item {
  border-left: 5px solid transparent;
}

.list-group-item:hover,
.list-group-item:active,
.list-group-item:focus {
  background: transparent;
  /* border: 1px solid var(--bg-sidebar-hover); */
  /* border-left: 5px solid #FF8B03; */
  color: #FF7128;
  outline: 0;
}

.list-group-item svg path {
  fill: #fff;
}

.list-group-item:hover svg path,
.list-group-item:active svg path,
.list-group-item:focus svg path {
  fill: #FF7128;
}

.list-group-item span::before {
  font-size: 15px;
  margin-right: 8px;
}

.active-menu {
  border-left: 5px solid #FA5805 !important;
}
