.inputText {
    margin: 0!important;
    margin-bottom: 20px!important;
    padding: 0!important;
}

.inputTextLabel {
    font-size: 12px;
    color: #5A6170;
    margin-bottom: 9px;
}

.inputTextErr {
    position: absolute;
    color: #ff5555;
    font-size: 10px;
    bottom: -15px;
    right: 0;
}

.Select {
    margin-bottom: 20px!important;
}

.Select [class*="-menu"] {
    z-index: 9999999999999;
}

.Select * {
    outline: 0!important;
    box-shadow: 0 0 0 transparent!important;
}

.SelectLabel {
    margin: 0;
}

.InputAvatar {
    position: relative;
    width: 102px;
    height: 102px;
    margin: 0 auto;
}

.InputAvatar .InputAvatarImg {
    width: 100px;
    height: 100px;
}

.InputAvatar .InputAvatarLabel {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.Ranking {
    margin: 20px 0;
    display: block;
}

.ProgressBar {
    color: #5A6170;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    text-align: right;
    letter-spacing: 0.04em;
}

.ProgressBar .progress-bar {
    border-radius: 100px;
    /* margin-bottom: 20px; */
}

.ProgressBar .progress {
    margin-bottom: 20px;
}

.ProgressBar p {
    text-align: center;
    font-weight: normal;
}

.InputCalendarRange {
    min-width: 300px;
    border: none;
    margin-bottom: 15px;
    outline: 0;
    line-height: 11.72px;
    border-radius: 0;
    padding: 3px 9px;
    letter-spacing: 0.85px;
    font-size: 14px;
    margin-top: 15px;
    border: none;
    border-bottom: 0.5px solid #9da6b8;
    background-color: transparent;
}

.InputCalendar {
    margin-bottom: 20px!important;
    padding: 0!important;
}

.InputCalendarLabel {
    margin-bottom: 9px;
}

.InputPassword {
    margin: 0!important;
    margin-bottom: 20px!important;
    padding: 0!important;
}

.InputPasswordContainer {
    position: relative;
}

.InputPasswordOpenModal {
    position: absolute;
    right: 0;
    bottom: 5px;
    cursor: pointer;
}

.inputPasswordLabel {
    margin-bottom: 9px;
}

.input:focus {
    outline: 0!important;
    border-color: none!important;
    box-shadow: none!important;
}

.flatpickr-input {
    margin: 0!important;
    padding: 7px!important;
}

.flatpickr-months .flatpickr-month, .flatpickr-current-month .flatpickr-monthDropdown-months, .flatpickr-weekdays {
    background: #000 !important;
}

span.flatpickr-weekday {
    background: #000 !important;
    color: #fff !important;
}

.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #000 !important;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background: #ff8b03 !important;
    border-color: #ff8b03 !important;
}

.form-select [class*="-control"] {
    border: none!important;
    border-bottom: 1px solid #9da6b8!important;
    border-radius: 0;
}

.form-select [class*="-indicatorContainer"] {
    color: #000!important;
}

.form-select [class*="-indicatorSeparator"] {
    display: none!important;
}

.form-select [class*="-placeholder"] {
    color: #9da6b8!important;
    font-size: 14px!important;
}

.form-select [class*="-singleValue"] {
    /* color:#9da6b8!important; */
    font-size: 14px!important;
}

.form-select:focus {
    border: none!important;
    box-shadow: 0 0 0 transparent!important;
    outline: 0!important;
}

.form-select [class*="-control"]:focus {
    border: none!important;
    box-shadow: 0 0 0 transparent!important;
    outline: 0!important;
}

.form-select [class*="-ValueContainer"] {
    padding: 0;
}

.servicePill {
    padding: 10px 15px;
    font-size: 14px;
    line-height: 21px;
    border-radius: 4px;
    color: #fff;
    /* margin-bottom: 15px; */
    position: relative;
}

.servicePill label {
    position: absolute;
    bottom: -15px;
    right: 0;
}

.servicesPillContainer {
    border: 2px dashed #b5b8c9;
    border-radius: 20px;
    padding: 15px 0;
    margin-bottom: 55.5px;
}

.servicePill .modal-body h4, .servicePill .modal-body span {
    color: #5A6170;
}

.react-tel-input .flag-dropdown {
    background: transparent!important;
}

.addServicesBtn {
    padding: 0 15px;
    cursor: pointer;
    font-size: 14px;
}

.SelectMultiCheck {
    display: none;
}

.SelectMultiLabel {
    padding: 8px 12px;
    border-radius: 8px;
    margin: 4px;
    color: #fff;
    background: #ccc;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.SelectMultiCheck:checked+label.SelectMultiLabel {
    background: #FF8B03
}

.SelectLight [class*="-control"] {
    border: none!important;
    background-color: transparent;
    padding: 0 10px;
}