.mytoggle {
    display: inherit;
}

.mytoggle input[type="checkbox"] {
    display: none;
}

.mytoggle label {
    width: 5rem;
    display: block;
    margin: 1.5rem auto;
}

.mytoggle .buttonbackground {
    background-color: #ccc;
    border-color: #ccc;
    width: 40px;
    height: 15px;
    border-radius: 1.25rem;
    position: relative;
    transition: background-color 0.2s ease;
    margin: 0.75rem auto;
    display: block
}

.mytoggle .buttonslider {
    background: #ddd;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #aaa;
    top: -4px;
    position: absolute;
    left: 0;
    transition: all 0.2s ease;
    display: block
}

.mytoggle input[type="checkbox"]+label:hover {
    cursor: pointer;
}

.mytoggle input[type="checkbox"]:checked+label .buttonbackground {
    background-color: #64b464;
}

.mytoggle input[type="checkbox"]:checked+label .buttonslider {
    left: 20px;
    background: #eee;
}