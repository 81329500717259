@import url("../src/assets/icons/flaticon.css");
/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
/* Estilos Generales */

:root {
  --bg-sidetools: #fafafa;
  --bg-layout: #f5f5f5;
  --color-text: #5a6170;
  --bg-sidebar: #000;
  --bg-sidebar-hover: #fafafa;
  --bg-sidebar-hover-text: #111;
  --primary-color: #FA5805 !important;
  --primary-color-hover: #FA5805 !important;
  --secondary-color: #000000;
  --secondary-color-hover: #5a6170;
  --white-color: #fff;
  --black-color: #000;
  --blue-color: #2178fc;
  --red-color: #ff1b1b;
  --green-color: #43c548;
}

body, html {
  /* font-family: "Roboto", sans-serif;*/
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  background-color: var(--bg-layout);
  color: var(--color-text);
  overflow-y: hidden;
}

.relative {
  position: relative
}

button:focus {
  outline: 0;
  border-color: none;
  box-shadow: none;
}

button, .cursor-pointer {
  cursor: pointer;
}

h1, .h1 {
  font-size: 28px;
  line-height: 48px;
}

h2, .h2 {
  font-size: 24px;
}

h3, .h3 {
  font-size: 20px;
}

h4, .h4 {
  font-size: 18px;
  line-height: 27px;
}

h5, .h5 {
  font-size: 16px;
}

h6, .h6 {
  font-size: 14px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  letter-spacing: 0.6px;
  font-weight: 400;
  margin-bottom: 0;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

/* Estilos Inputs */

form .form-control, .form-control, form select.custom-select, select.custom-select {
  width: 100%;
  border: none;
  margin-bottom: 15px;
  outline: 0;
  line-height: 11.72px;
  border-radius: 0;
  padding: 3px 9px;
  letter-spacing: 0.85px;
  font-size: 14px;
  margin-top: 15px;
  border: none;
  border-bottom: 0.5px solid #9da6b8;
  background-color: transparent;
}

form select.custom-select, select.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../src/assets/icons/arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 10px;
  color: #9da6b8;
  box-shadow: none !important;
  line-height: 1.5;
}

/* form select.custom-select:focus, select.custom-select:focus {
  border-color: #ff8b03;
} */

/* .form-control:focus,.input:focus {
  outline: 0!important;
  border-color: none!important;
  box-shadow: none!important;
} */

.form-control::placeholder {
  color: #9da6b8;
  font-weight: 400;
  font-size: 14px;
}

.form-control:disabled, .form-control[readonly] {
  background-color: transparent;
}

/* Estilos scroll */

.scroll-list {
  max-height: 70vh;
  overflow-y: auto;
}

/* width */

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #fcfcfc;
  border: 1px solid #e6e6e6;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background-color: #a2c1e0;
  border-radius: 20px;
  border: 1px solid #fcfcfc;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #b9c6e0;
  cursor: pointer;
}

/* Scrollbar Style */

#style-1::-webkit-scroll-list-track {
  border-radius: 2px;
}

#style-1::-webkit-scroll-list {
  width: 5px;
  background-color: #f7f7f7;
}

#style-1::-webkit-scroll-list-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #bfbfbf;
}

/* this is for firefox */

* {
  scrollbar-width: thin;
  scrollbar-color: #a2c1e0 #fcfcfc;
}

.description-box {
  border-radius: 5px;
  min-height: 100px;
  margin: 15px auto;
  padding: 10px 0;
  background-color: #e9edf9;
}

.form-control:focus {
  border: none;
  border-bottom: 1px solid;
  box-shadow: 0 0 0 0.2rem transparent;
  outline: 0;
}

.table-vertical-center td {
  vertical-align: middle;
}

.table-vertical-center td, .table-vertical-center th {
  border-top: none;
  border-bottom: 1px solid #dee2e6;
}

form .form-control, .form-control, form select.custom-select, select.custom-select {
  border-bottom: none!important;
}

.SelectLight [class*="-control"] {
  padding: 0 !important;
}

.card-title {
  margin: 0!important
}


.pure-modal .panel-footer{
  padding: 15px!important;
}


.pure-modal .panel-footer button{
  min-width: 150px!important;
}

.DatePicker, .DatePicker input{
  width: 100%!important;
}