/* Estilos Navbar */

.navbar {
  top: 0px;
  left: 0px;
  height: 65px;
  background-color: #fff;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.01.0);
  opacity: 1;
  padding: 0 15px !important;
}

.navbar .container-avatar {
  cursor: pointer;
  display: flex;
  align-items: center;
  /* padding: 10px; */
  border: none;
  background: transparent;
}

.mobile-none {
  display: block !important;
}

@media (max-width: 991px) {
  .mobile-none {
    display: none !important;
  }
}
